/**
 * Created by alext on 14-11-27.
 */
/*
requires salvattore and infinite scroll helper to be previously attached to the document

 */

function setupScroll(selector, numPerPage, grid, category, tag) {
  var offset = numPerPage; // assume the original results have already been loaded
  var pageNum = 1;

  $(selector).infiniteScrollHelper({
    loadMore: function(page, done) {
      pageNum += 1;

      var nextPageUrl = "/v2/api/blogpostpartials/?page=" + pageNum + "&offset=" + offset;

      if (category) {
        nextPageUrl += "&category=" + category;
      }

      if (tag) {
        nextPageUrl += "&tag=" + tag;
      }

      $.get(nextPageUrl, function(data) {

        var parsed = $.parseHTML(data);
        var elems = $(parsed).filter('div.panel').get();
        salvattore.append_elements(grid, elems);

        if (elems.length < numPerPage) {
          // we are done

          $(selector).infiniteScrollHelper('destroy');
        }

        $(document).foundation('interchange', 'reflow'); // trigger re-interchange
        $(document).foundation('interchange', 'resize'); // trigger re-interchange https://github.com/zurb/foundation/issues/5180

        done();
      })


    }
  })
}